<template>
  <v-container fluid class="px-8">
    <v-row class="my-2">
      <v-col>
        <core-btn-secondary :loading="isLoading" @click="getLatestVideo()"
          >Get Latest Service<v-icon>mdi-download</v-icon></core-btn-secondary
        >
      </v-col>
      <!-- <v-col>
          <core-btn-primary :loading="isLoading" @click="importPodcast()">Import</core-btn-primary>
        </v-col> -->
    </v-row>
    <!-- Table -->
    <v-form v-model="isValid">
      <v-data-table
        ref="table"
        v-model="selected"
        :headers="headers"
        :items="rows"
        :loading="isLoading"
        :items-per-page.sync="itemsPerPage"
        :footer-props="{ 'items-per-page-options': [5, 10, 20, 50, -1] }"
        class="elevation-1"
        sort-by="date"
        sort-desc
        @input="itemSelected($event)"
        @toggle-select-all="allSelected($event)">
        <template v-slot:top>
          <!-- Header -->
          <v-toolbar dark color="primary" class="mb-1">
            <v-row class="d-flex align-center justify-space-between" no-gutters>
              <h2>New Hope Podcast Uploader</h2>
            </v-row>
          </v-toolbar>
        </template>
        <!-- Body -->

        <!-- Date -->
        <template v-slot:[`item.date`]="{ item }">
          <v-row class="mx-0 mt-1 d-flex justify-center">
            <div v-if="item.youtube">
              <v-btn :href="item.youtube" color="secondary" text>
                {{ item.date }}<v-icon>mdi-youtube</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <h4>{{ item.date }}</h4>
            </div>
          </v-row>
        </template>
        <!-- Title -->
        <template v-slot:[`item.title`]="{ item, header, index }">
          <v-row class="mx-0 mt-1">
            <core-textarea-table
              v-model="item.title"
              :loading="header.updating[index]"
              :hint="header.updating[index] ? 'Updating' : ''"
              :readonly="item.synced_to_rss"
              :filled="!item.synced_to_rss"
              @change="updateRow(item, header, index)"></core-textarea-table>
          </v-row>
        </template>

        <!-- Title -->
        <template v-slot:[`item.description`]="{ item, header, index }">
          <v-row class="mx-0 mt-1">
            <core-textarea-table
              v-model="item.description"
              :loading="header.updating[index]"
              :hint="header.updating[index] ? 'Updating' : ''"
              :readonly="item.synced_to_rss"
              :filled="!item.synced_to_rss"
              @change="updateRow(item, header, index)"></core-textarea-table>
          </v-row>
        </template>

        <!-- Series -->
        <template v-slot:[`item.series`]="{ item, header, index }">
          <v-row class="mx-0 mt-2 pb-1">
            <core-select-table
              v-model="item.series"
              :items="series"
              :loading="header.updating[index]"
              :hint="header.updating[index] ? 'Updating' : ''"
              :readonly="item.synced_to_rss"
              :filled="!item.synced_to_rss"
              item-text="title"
              item-value="id"
              @input="updateRow(item, header, index)">
            </core-select-table>
          </v-row>
          <v-row class="mx-0 mt-1">
            <core-number-field-table
              v-model="item.track_number"
              :loading="header.updating[index]"
              :hint="header.updating[index] ? 'Updating' : ''"
              :readonly="item.synced_to_rss"
              :filled="!item.synced_to_rss"
              @change="updateRow(item, header, index)"></core-number-field-table>
          </v-row>

          <!-- Pastor -->
          <v-row class="mx-0 mt-2 pb-1">
            <core-select-table
              v-model="item.pastor"
              :items="pastors"
              :loading="header.updating[index]"
              :hint="header.updating[index] ? 'Updating' : ''"
              :readonly="item.synced_to_rss"
              :filled="!item.synced_to_rss"
              item-value="id"
              item-text="name"
              @change="updateRow(item, header, index)">
            </core-select-table>
          </v-row>
        </template>

        <!-- Start Time -->
        <template v-slot:[`item.start_time`]="{ item, header, index }">
          <v-row class="mx-0 mt-1">
            <core-number-field-table
              v-model="item.start_time"
              :loading="header.updating[index]"
              :hint="header.updating[index] ? 'Updating' : ''"
              :readonly="item.synced_to_rss"
              :filled="!item.synced_to_rss"
              @change="updateRow(item, header, index)"></core-number-field-table>
          </v-row>
          <v-row class="mx-0 mt-1">
            <core-number-field-table
              v-model="item.end_time"
              :loading="header.updating[index]"
              :hint="header.updating[index] ? 'Updating' : ''"
              :readonly="item.synced_to_rss"
              :filled="!item.synced_to_rss"
              @change="updateRow(item, header, index)"></core-number-field-table>
          </v-row>
        </template>
        <!-- Download -->
        <template v-slot:[`item.download`]="{ item }">
          <v-row class="mx-0">
            <v-btn :loading="isLoading" color="primary" outlined small text @click="download(item)">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-row>
          <v-row class="mx-0 pt-2">
            <v-btn :disabled="item.audio == null" color="secondary" outlined small text :href="item.audio">
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
          </v-row>
        </template>

        <!-- Synced -->
        <template v-slot:[`item.synced_to_rss`]="{ item }">
          <v-row class="mx-0 d-flex justify-center">
            <v-btn
              :loading="isLoading"
              :disabled="item.audio == null || item.synced_to_rss"
              color="primary"
              outlined
              small
              text
              @click="upload(item)">
              <v-icon>mdi-upload</v-icon>
            </v-btn>
          </v-row>
          <v-row class="mx-0 pt-2 d-flex justify-center">
            <v-icon v-if="item.synced_to_rss" color="green">mdi-check-circle</v-icon>
            <v-icon v-else color="red">mdi-alpha-x-circle</v-icon></v-row
          >
        </template>
        <!-- Synced -->
        <template v-slot:[`item.thumbnail_url`]="{ item }">
          <v-row class="mx-0 d-flex justify-center">
            <img :src="item.thumbnail_url" width="80" height="80" />
          </v-row>
        </template>
      </v-data-table>
      <v-row class="mt-1">
        <v-col cols="12">
          <h2>Timestamp Calculator</h2>
        </v-col>
        <v-col cols="2">
          <core-number-field label="Minutes" filled v-model="minutes"></core-number-field>
        </v-col>
        <v-col cols="2">
          <core-number-field label="Seconds" filled v-model="seconds"></core-number-field>
        </v-col>
        <v-col cols="2">
          <core-number-field readonly label="Total Seconds" :value="totalSeconds"></core-number-field>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      isLoading: true,
      isSaving: false,
      isValid: true,
      itemsPerPage: 5,
      headers: [
        { text: "Date", value: "date", updating: [] },
        { text: "Title", value: "title", width: "300", updating: [] },
        { text: "Series / Track # / Messenger", value: "series", updating: [] },
        { text: "Description", value: "description", width: "320", updating: [] },
        { text: "Start / End (seconds)", value: "start_time", updating: [] },
        { text: "Audio Download", value: "download" },
        { text: "Sync to RSS Feed", value: "synced_to_rss" },
        { text: "Thumbnail", value: "thumbnail_url" },
      ],
      rows: [],
      series: [],
      pastors: [],

      selected: [],
      selectedGroup: "",
      minutes: 0,
      seconds: 0,
    }
  },
  computed: {
    totalSeconds() {
      return 60 * parseInt(this.minutes) + parseInt(this.seconds)
    },
  },
  methods: {
    async updateRow(rowData, header, rowIndex, event) {
      let endpoint = "new_hope/podcast_episode/"

      if (this.isValid) {
        header.updating[rowIndex] = true
        this.$forceUpdate()

        await this.$django_api
          .put(endpoint, rowData)
          .then((response) => {
            Object.assign(rowData, response.data)
          })
          .catch((error) => {
            Object.assign(rowData, error.response.data.original)
          })

        setTimeout(() => {
          header.updating[rowIndex] = false
          this.$forceUpdate()
        }, 1000)
      }
    },

    async download(episode) {
      this.isLoading = true
      let alert = {
        "type": "warning",
        "message": "Processing the audio may take a few minutes. Please be patient.",
      }
      this.$store.dispatch("set_alert", alert)

      await this.$django_api
        .get("new_hope/youtube_download?id=" + episode.id, { timeout: 360000 })
        .then((response) => {
          episode.audio = response.data.audio

          let alert = {
            "type": "success",
            "message": "Audio processed. Please verify audio quality before uploading.",
          }
          this.$store.dispatch("set_alert", alert)
        })
        .catch((error) => {
          this.isLoading = false
        })

      this.isLoading = false
    },
    async upload(episode) {
      this.isLoading = true

      await this.$django_api
        .get("new_hope/podcast_upload?id=" + episode.id, { timeout: 240000 })
        .then((response) => {
          if (response.status == 200) {
            episode.synced_to_rss = response.data.synced_to_rss
            let alert = {
              "type": "success",
              "message": "Podcast published.",
            }
            this.$store.dispatch("set_alert", alert)
          }
        })
        .catch((error) => {
          this.isLoading = false
        })

      this.isLoading = false
    },
    async getLatestVideo(episode) {
      this.isLoading = true

      await this.$django_api.get("new_hope/get_latest_youtube").then((response) => {
        let alert = {
          "type": "success",
        }

        if (response.data.created) {
          this.rows.push(response.data.episode)
          alert.message = "Synced with Youtube. Grabbed message from most recent Sunday."

          this.$django_api.get("new_hope/pastor").then((response) => {
            this.pastors = response.data
          })
          this.$django_api.get("new_hope/series").then((response) => {
            this.series = response.data
          })
        } else {
          alert.message = "Synced with Youtube. No new episodes to grab."
        }

        this.$store.dispatch("set_alert", alert)
      })

      this.isLoading = false
    },
    async importPodcast() {
      this.isLoading = true

      await this.$django_api.get("new_hope/podcast_import", { timeout: 3600000 }).then((response) => {
        let alert = {
          "type": "success",
          "message": "Import successful.",
        }

        this.$store.dispatch("set_alert", alert)
      })

      this.isLoading = false
    },
  },
  mounted: async function () {
    this.isLoading = true

    await this.$django_api.get("new_hope/pastor").then((response) => {
      this.pastors = response.data
    })
    await this.$django_api.get("new_hope/series").then((response) => {
      this.series = response.data
    })

    await this.$django_api.get("new_hope/podcast_episode").then((response) => {
      this.rows = response.data
      this.isLoading = false
    })
  },
  watch: {},
}
</script>
